import React, { useEffect, useState } from 'react';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

const SEEN_LOGIN_GATE = 'SeenLoginGate';

const wasLoginDialogShown = () =>
  localStorage.getItem(SEEN_LOGIN_GATE) && localStorage.getItem(SEEN_LOGIN_GATE) === 'true';

let LoginDialog;

type TLoginDialogApp = {
  environment: {
    userLoggedIn: boolean;
    isBot: boolean;
    hasBannerBeenDismissed: boolean;
    staticBaseUrl: string;
    xsrfToken: string;
    baseUrl: string;
    links: object;
  };
};

const LoginDialogApp = ({ environment }: TLoginDialogApp) => {
  const { userLoggedIn, isBot, hasBannerBeenDismissed } = environment;
  const [isLoginDialogLoaded, setIsLoginDialogLoaded] = useState(false);

  useEffect(() => {
    if (!isBot && !userLoggedIn && hasBannerBeenDismissed && !wasLoginDialogShown()) {
      const loadComponent = async () => {
        try {
          LoginDialog = (await import(/* webpackChunkName: "loginDialog" */ './components/LoginDialog/LoginDialog'))
            .default;
          setIsLoginDialogLoaded(true);
          localStorage.setItem(SEEN_LOGIN_GATE, 'true');
        } catch (err) {}
      };

      loadComponent();
    }
  }, [userLoggedIn, isBot, hasBannerBeenDismissed]);

  if (!isLoginDialogLoaded) {
    return null;
  }

  return (
    <EnvironmentProvider value={environment}>
      <LoginDialog />
    </EnvironmentProvider>
  );
};

export default LoginDialogApp;
