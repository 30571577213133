import React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { I18nContextProvider } from '@ecg-marktplaats/js-react-i18n';
import { Header } from '@hz-design-system/web-ui';

import HomepageApp from '../../apps/Homepage';
import LoginDialogApp from '../../apps/LoginDialog';
import MobileBannerApp from '../../apps/MobileBanner';
import CategoryMenuBarApp from '../../apps/CategoryMenuBar/CategoryMenuBar';
import SmartBannerFullScreenApp from '../../apps/SmartBannerAndroidApp/SmartBannerFullScreenApp/SmartBannerFullScreenApp';
import { trackGAEvent } from '../../utils/gaTracking';

import '../../../scss/global.scss';
// server side only components styles for this page
import '../../../../server/pages/homepage.styles';

export default (i18n: any) => {
  // eslint-disable-next-line no-underscore-dangle
  const appState = window.__CONFIG__ || {};
  const environment = window.__ENV_CONFIG__ || {};
  const headerConfig = window.__HEADER_CONFIG__ || {};
  const headerRoot = document.getElementById('header-root');
  const smartBannerAndroidRoot = document.getElementById('smart-banner-android-root');
  const categoryMenuBarRoot = document.getElementById('category-menu-bar-root');
  const topBlockRoot = document.getElementById('top-block-root');
  const homepageRoot = document.getElementById('homepage-root');
  const { showSmartBanner } = environment;

  if (showSmartBanner) {
    hydrateRoot(
      smartBannerAndroidRoot!,
      <I18nContextProvider i18nInstance={i18n}>
        <SmartBannerFullScreenApp environment={environment} />
      </I18nContextProvider>,
    );
  }

  // Header is server side rendered in express-react library
  hydrateRoot(headerRoot!, <Header headerConfig={headerConfig} trackGAEvent={trackGAEvent} />);

  hydrateRoot(
    categoryMenuBarRoot!,
    <I18nContextProvider i18nInstance={i18n}>
      <CategoryMenuBarApp appState={appState} environment={environment} />
    </I18nContextProvider>,
  );

  hydrateRoot(topBlockRoot!, <MobileBannerApp environment={environment} />);

  hydrateRoot(
    homepageRoot!,
    <I18nContextProvider i18nInstance={i18n}>
      <HomepageApp appState={appState} environment={environment} />
      <LoginDialogApp environment={environment} />
    </I18nContextProvider>,
  );
};
